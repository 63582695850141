
import { Component, Prop, Vue } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Venue } from '../../interfaces/models/Venue';
import { InputType } from '../../enums/InputType';
import { venueFilter } from '@/util/helper';
import { Coupon } from '@/interfaces/models/Coupon';

@Component({
  components: { VFormBuilder },
})
export default class DisableCouponForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true }) public venues!: Venue[];
  @Prop({ type: Array, required: true }) public coupons!: Coupon[];

  get items() {
    return [
      {
        name: 'venue',
        type: InputType.Autocomplete,
        label: 'coupon.form.venue',
        rules: 'required',
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        filter: venueFilter,
      },
      {
        name: 'coupons',
        type: InputType.Select,
        label: 'coupon.form.codes',
        rules: 'required',
        items: this.couponItems,
        multiple: true,
      },
    ];
  }

  get couponItems() {
    return this.coupons.map((p: Coupon) => ({ text: p.name, value: p._id }));
  }
}
