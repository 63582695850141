
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import DisablePromoCodeForm from '../../components/promo/DisablePromoCodeForm.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { PromoCodeApiService } from '@/api/http/PromoCodeApiService';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { PromoCode } from '@/interfaces/models/PromoCode';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import DisablePromoCodeLoader from '@/components/promo/DisablePromoCodeLoader.vue';

const app = namespace('app');

@Component({
  components: { DisablePromoCodeLoader, DisablePromoCodeForm, VWrapper },
})
export default class DisablePromoCode extends mixins(StackedForm, Notification) {
  @app.State('venues') public venues!: Venue[];

  public $refs!: {
    form: InstanceType<typeof DisablePromoCodeForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };

  protected codes: PromoCode[] = [];

  public mounted(): void {
    this.$startLoading('promo');
    const api: PromoCodeApiService = new PromoCodeApiService();
    api
      .index(null)
      .then((res: AxiosResponse<PromoCode[] | Pagination<PromoCode>>) => {
        this.codes = res.data as PromoCode[];
      })
      .finally(() => {
        this.$stopLoading('promo');
      });
  }

  public disable() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        this.$startLoading('promo.disable');
        const api: PromoCodeApiService = new PromoCodeApiService();
        api
          .disableCodes({ ...this.$refs.form.getData() })
          .then(() => {
            this.notifyInfo('promo.notification.disableSuccess');
          })
          .finally(() => {
            this.$stopLoading('promo.disable');
          });
        this.$router.push({ name: 'promo.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
