
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '../../components/shared/VWrapper.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import DisableCouponLoader from '@/components/coupon/DisableCouponLoader.vue';
import DisableCouponForm from '@/components/coupon/DisableCouponForm.vue';
import { CouponApiService } from '@/api/http/CouponApiService';
import { Coupon } from '@/interfaces/models/Coupon';

const app = namespace('app');

@Component({
  components: { DisableCouponForm, DisableCouponLoader, VWrapper },
})
export default class DisableCoupon extends mixins(StackedForm, Notification) {
  @app.State('venues') public venues!: Venue[];

  public $refs!: {
    form: InstanceType<typeof DisableCouponForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };

  protected coupons: Coupon[] = [];

  public mounted(): void {
    this.$startLoading('coupon');
    const api: CouponApiService = new CouponApiService();
    api
      .index(null)
      .then((res: AxiosResponse<Coupon[] | Pagination<Coupon>>) => {
        this.coupons = res.data as Coupon[];
      })
      .finally(() => {
        this.$stopLoading('coupon');
      });
  }

  public disable() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        this.$startLoading('coupon.disable');
        const api: CouponApiService = new CouponApiService();
        api
          .disableCoupon({ ...this.$refs.form.getData() })
          .then(() => {
            this.notifyInfo('coupon.notification.disableSuccess');
          })
          .finally(() => {
            this.$stopLoading('coupon.disable');
          });
        this.$router.push({ name: 'coupon.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
